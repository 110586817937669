import TYPES from '@/types';
import { currencyFormat } from '@/vue-app/utils/currency';
import { getGoalIconPath } from '@/vue-app/utils/goal-icon-path';

// Application
import GetCustomerInvestorGoalBalanceQuery from '@/modules/flagship/customer-investor-goal-balance/application/query/get-customer-investor-goal-balance';
import DistributeUnassignedBalanceCommand
  from '@/modules/flagship/investor-goal/distribute-unassigned-balance/application/commands/distribute-unassigned-balance-command';

// Domain
import {
  CreateInvestorGoalStateManager,
} from '@/modules/flagship/investor-goal/investor_goal/domain/state/create-investor-goal-state-manager';
import { InvestorGoal }
  from '@/modules/flagship/investor-goal/distribute-unassigned-balance/domain/entities/distribute-unassigned-balance-entity';
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import { DateFormatter } from '@/modules/shared/domain/date-formatters';

type LinkedGoals = {
  investor_goal_id: string;
  name: string;
  label: string;
  icon: string;
  assigned_amount: number;
  recommend_amount: number;
  alt: string;
  assigned_amount_field: string;
  is_moderate: boolean;
  icon_path: string;
}

export default class DistributeUnallocatedBalanceViewModel {
  @Inject(TYPES.GET_CUSTOMER_INVESTOR_GOAL_BALANCE_QUERY)
  private readonly get_customer_investor_goal_balance_query!: GetCustomerInvestorGoalBalanceQuery;

  @Inject(TYPES.DISTRIBUTE_UNASSIGNED_BALANCE_COMMAND)
  private readonly distribute_unassigned_balance_command!: DistributeUnassignedBalanceCommand;

  @Inject(TYPES.CREATE_INVESTOR_GOAL_STATE_MANAGER)
  readonly create_investor_goal_state_manager!: CreateInvestorGoalStateManager;

  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  @Inject(TYPES.DATE_FORMATTER)
  readonly date_formatter!: DateFormatter;

  readonly i18n_namespace = 'components.goals-dashboard.unallocated-balance.distribute-balance-unallocated';

  linked_goals: Array<LinkedGoals> = [];

  deposit_amount = 0;

  step = 50;

  is_disabled = true;

  index_unassigned_amount = 0;

  investor_goal_state = this.create_investor_goal_state_manager.state;

  private readonly view: any;

  constructor(view: any) {
    this.view = view;
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  get deposit_amount_formatted() {
    return currencyFormat(this.deposit_amount);
  }

  getIconMaxWidth = (icon_goal_name: string) => ((icon_goal_name === 'icon-add.svg') ? '28px' : '21px');

  getAmountFormatted = (amount: number) => (currencyFormat(amount));

  getCustomizedModerateClassForMainVCol = (is_moderate: boolean) => ((is_moderate) ? 'white--text primary rounded-t-lg' : '');

  getCustomizedModerateBackgroundColorForVTextField = (is_moderate: boolean) => ((is_moderate) ? 'primary' : '');

  getCustomizedModerateClassForVTextField = (is_moderate: boolean) => ((is_moderate) ? 'white-font' : '');

  loadActiveGoals = async () => {
    this.linked_goals.push({
      investor_goal_id: this.investor_goal_state.investor_goal.id,
      name: this.investor_goal_state.investor_goal.goal_name,
      label: this.investor_goal_state.investor_goal.goal_name,
      icon: this.investor_goal_state.investor_goal.icon,
      assigned_amount: 0,
      recommend_amount: this.investor_goal_state.investor_goal.monthly_required_amount,
      alt: this.investor_goal_state.investor_goal.goal_name,
      assigned_amount_field: '0.00',
      is_moderate: false,
      icon_path: getGoalIconPath(
        this.investor_goal_state.investor_goal.id,
        this.investor_goal_state.investor_goal.icon,
        '',
      ),
    });
  }

  addItemUnassignedAmount = () => {
    this.linked_goals.push({
      investor_goal_id: '',
      name: 'unassigned',
      label: 'Saldo sin asignar',
      icon: 'noun-coins.svg',
      assigned_amount: 0,
      recommend_amount: 0,
      alt: 'alts.unassigned',
      assigned_amount_field: '0.00',
      is_moderate: false,
      icon_path: getGoalIconPath('', 'noun-coins.svg', ''),
    });
    this.index_unassigned_amount = this.linked_goals.length - 1;
  }

  incrementAmount = (index: number) => {
    let current_amount = this.linked_goals[index].assigned_amount;
    current_amount += this.step;
    if (this.deposit_amount >= current_amount) {
      this.linked_goals[index].assigned_amount += this.step;
      this.linked_goals[index].assigned_amount_field = this.linked_goals[index]
        .assigned_amount.toFixed(2);
      this.validateTotalAmounts(false);
    }
  }

  decrementAmount = (index: number) => {
    let current_amount = this.linked_goals[index].assigned_amount;
    current_amount -= this.step;
    if (this.linked_goals[index].assigned_amount > 0 && current_amount >= 0) {
      this.linked_goals[index].assigned_amount -= this.step;
      this.linked_goals[index].assigned_amount_field = this.linked_goals[index]
        .assigned_amount.toFixed(2);
      this.validateTotalAmounts(true);
    }
  }

  addUnassignedAmount = (total_amount_goals: number) => {
    if (total_amount_goals < this.deposit_amount) {
      const res = this.deposit_amount - total_amount_goals;
      if (res >= this.step) {
        this.linked_goals[this.index_unassigned_amount].assigned_amount += this.step;
      }
    }
  }

  subtractUnassignedAmount = (total_amount_goals: number) => {
    if (total_amount_goals <= this.deposit_amount) {
      this.linked_goals[this.index_unassigned_amount].assigned_amount -= this.step;
    }
  }

  validateTotalAmounts = (is_add: boolean, amount_was_edited_field = false) => {
    const linked_goals_assigned_amount = this.linked_goals.map(
      (obj, index) => ((index !== this.index_unassigned_amount) ? obj.assigned_amount : 0),
    );
    let total_amount_goals = linked_goals_assigned_amount.reduce(
      (total, currentValue) => total + currentValue, 0,
    );
    if (!amount_was_edited_field) {
      if (is_add) {
        this.addUnassignedAmount(total_amount_goals);
      } else {
        this.subtractUnassignedAmount(total_amount_goals);
      }
    }
    total_amount_goals += this.linked_goals[this.index_unassigned_amount].assigned_amount;
    this.is_disabled = total_amount_goals !== this.deposit_amount;
  }

  changeAssignedAmountField = (index: number) => {
    const parsed_assigned_amount_field = (this.linked_goals[index].assigned_amount_field)
      ? Number(parseFloat(this.linked_goals[index].assigned_amount_field.replaceAll(/[^\d.-]/g, '')))
      : 0;
    const is_add = (parsed_assigned_amount_field > this.linked_goals[index].assigned_amount);
    // eslint-disable-next-line max-len
    if (parsed_assigned_amount_field >= 0 && this.deposit_amount >= parsed_assigned_amount_field) {
      this.linked_goals[index].assigned_amount_field = parsed_assigned_amount_field.toFixed(2);
      this.linked_goals[index].assigned_amount = parsed_assigned_amount_field;
    } else {
      this.linked_goals[index].assigned_amount_field = '0.00';
      this.linked_goals[index].assigned_amount = 0;
    }
    const linked_goals_assigned_amount = this.linked_goals.map(
      (obj, position) => ((position !== this.index_unassigned_amount) ? obj.assigned_amount : 0),
    );
    const total_amount_goals = linked_goals_assigned_amount.reduce(
      (total, currentValue) => total + currentValue, 0,
    );
    if (total_amount_goals <= this.deposit_amount) {
      this.linked_goals[this.index_unassigned_amount]
        .assigned_amount = this.deposit_amount - total_amount_goals;
    }
    this.validateTotalAmounts(!is_add, true);
  }

  cancel = () => {
    this.investor_goal_state.goal_created_and_linked = false;
    this.investor_goal_state.goal_linked_successfully = true;
  }

  distributeBalance = async () => {
    let total_distributed_balance = 0;
    const investor_goals: InvestorGoal[] = [];

    this.linked_goals.forEach((goal: LinkedGoals) => {
      if (goal.investor_goal_id && goal.assigned_amount > 0) {
        investor_goals.push(
          {
            investor_goal_id: goal.investor_goal_id,
            amount_to_distribute: goal.assigned_amount.toString(),
          },
        );
        total_distributed_balance += goal.assigned_amount;
      }
    });
    this.investor_goal_state.total_active_goals_balance = total_distributed_balance;

    const unassigned_balance = this.investor_goal_state
      .available_balance - total_distributed_balance;

    if (investor_goals.length) {
      await this.distribute_unassigned_balance_command
        .execute({
          investment_product_id: this.investor_goal_state.associated_product_id,
          investor_goals,
          remaining_unassigned_balance: unassigned_balance.toFixed(6),
          total_distributed_balance: total_distributed_balance.toString(),
        });
    }
    this.investor_goal_state.goal_created_and_linked = false;
    this.investor_goal_state.goal_linked_successfully = true;
  }

  initialize = async () => {
    this.investor_goal_state.is_loading = true;
    this.deposit_amount = Math.floor(this.investor_goal_state.available_balance);
    await this.loadActiveGoals();
    this.addItemUnassignedAmount();
    this.investor_goal_state.is_loading = false;
  }
}
