






















































































































































import { Component, PropSync, Vue } from 'vue-property-decorator';

import DistributeUnallocatedBalanceViewModel
  from '@/vue-app/view-models/components/goals-dashboard/create-goal/unallocated-balance/distribute-unallocated-balance-view-model';

@Component({
  name: 'DistributeBalanceUnallocated',
  components: {
    CustomTooltip: () => import('@/vue-app/components/custom/CustomTooltip.vue'),
  },
})
export default class DistributeBalanceUnallocated extends Vue {
  @PropSync('isOpen', { type: Boolean, required: true })
  synced_is_open!: boolean;

  view_model = Vue.observable(
    new DistributeUnallocatedBalanceViewModel(this),
  );

  created() {
    this.view_model.initialize();
  }
}
