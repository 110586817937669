var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":_vm.$vuetify.breakpoint.md ? '40%' : _vm.$vuetify.breakpoint.lg ? '30%'
    : _vm.$vuetify.breakpoint.sm ? '60%' : _vm.$vuetify.breakpoint.xl  ? '40%' : '100%',"persistent":""},model:{value:(_vm.synced_is_open),callback:function ($$v) {_vm.synced_is_open=$$v},expression:"synced_is_open"}},[_c('v-card',{staticClass:"pa-10",attrs:{"rounded":"lg","fullscreen":_vm.$vuetify.breakpoint.xs}},[_c('v-row',{staticClass:"px-md-12 pb-13 text-center",attrs:{"no-gutters":"","justify":"center"}},[_c('v-col',{attrs:{"justify":"center"}},[_c('h3',{staticClass:"primary--text mb-4"},[_vm._v(" "+_vm._s(_vm.view_model.translate('title', { product_name: _vm.view_model.investor_goal_state.associated_product }))+" ")]),_c('h2',{staticClass:"text-center accent--text mb-2"},[_vm._v(" "+_vm._s(_vm.view_model.translate( 'balance', { amount: _vm.view_model .deposit_amount_formatted } ))+" ")]),_c('p',{staticClass:"primary--text text-text mt-8"},[_vm._v(" "+_vm._s(_vm.view_model .translate('description', { product_name: _vm.view_model.investor_goal_state.associated_product }))+" ")])])],1),_vm._l((_vm.view_model.linked_goals),function(item,index){return _c('v-row',{key:index,staticClass:"justify-center mt-3 mb-4",attrs:{"no-gutters":""}},[_c('v-col',{class:_vm.view_model
                   .getCustomizedModerateClassForMainVCol(item.is_moderate),attrs:{"cols":"12","md":"9"}},[_c('v-row',{staticClass:"justify-center mt-3",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-none d-md-block d-sm-block",attrs:{"cols":"1"}},[_c('v-img',{attrs:{"src":item.icon_path,"contain":"","max-width":_vm.view_model
                  .getIconMaxWidth(item.icon),"alt":item.label}})],1),_c('v-col',{attrs:{"cols":"5"}},[_c('span',{staticClass:"caption-text"},[_vm._v(_vm._s(item.label))])]),(item.name === 'unassigned')?_c('v-col',{attrs:{"cols":"5","md":"4"}},[_c('span',{staticClass:"text-amount"},[_vm._v(" "+_vm._s(_vm.view_model.translate('balance', { amount: _vm.view_model .getAmountFormatted(item.assigned_amount) } ))+" ")])]):_c('v-col',{attrs:{"cols":"5","md":"4"}},[_c('v-row',[_c('v-icon',{staticClass:"cursor-pointer",attrs:{"color":"inactive-blue"},on:{"click":function($event){_vm.view_model
                      .decrementAmount(index)}}},[_vm._v(" mdi-minus ")]),_c('v-text-field',{directives:[{name:"money",rawName:"v-money",value:({ prefix: '$', precision: 2 }),expression:"{ prefix: '$', precision: 2 }"}],staticClass:"text-amount centered-input",class:_vm.view_model
                    .getCustomizedModerateClassForVTextField(item.is_moderate),attrs:{"spellcheck":"false","flat":"","solo":"","hide-details":"","background-color":_vm.view_model
                    .getCustomizedModerateBackgroundColorForVTextField(
                      item.is_moderate)},on:{"change":function($event){_vm.view_model
                        .changeAssignedAmountField(index)}},model:{value:(item.assigned_amount_field),callback:function ($$v) {_vm.$set(item, "assigned_amount_field", $$v)},expression:"item.assigned_amount_field"}}),_c('v-icon',{staticClass:"cursor-pointer mb-1",attrs:{"color":"inactive-blue"},on:{"click":function($event){_vm.view_model
                          .incrementAmount(index)}}},[_vm._v("mdi-plus ")])],1)],1)],1),_c('v-divider',{staticClass:"mt-4"})],1)],1)}),_c('v-row',{staticClass:"mt-2",attrs:{"justify":"center","justify-md":"space-between"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{staticClass:"text-body",attrs:{"rounded":"","color":"secondary","large":"","block":"","height":"3.5em","disabled":_vm.view_model.investor_goal_state.is_loading},on:{"click":_vm.view_model.cancel}},[_vm._v(" "+_vm._s(_vm.view_model.translate('cancel'))+" ")])],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{staticClass:"text-body",attrs:{"rounded":"","color":"accent","large":"","block":"","height":"3.5em","disabled":_vm.view_model.is_disabled ||
              _vm.view_model.investor_goal_state.is_loading},on:{"click":_vm.view_model.distributeBalance}},[_vm._v(" "+_vm._s(_vm.view_model .translate('continue'))+" ")])],1)],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }